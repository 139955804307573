<template>
  <div class="info">
    <div class="jobshwheader"></div>
    <div class="min_body">
      <div class="job_describe">
        <!-- 职位要求及待遇岗位 -->
        <div class="job_describe_top">
          <div class="jjxx">店铺户外广告赋色网格员督促整改</div>
        </div>
        <div class="sj_job_box mt10"></div>
        <div class="job_describe_cengter">
          <div class="user_undergo_box">
            <div class="user_undergo user_undergo_a">
              店铺名称：{{ info.Shop_Name }}
            </div>
            <div class="user_undergo user_undergo_a">
              店铺地址：{{ info.Shop_Address }}
            </div>
            <div class="user_undergo user_undergo_a">
              店招名称：{{ info.Shop_Sign }}
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                近景照片：
              </div>
              <img
                v-for="(item, i) in info.CloseShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="closeShotsPreview"
              />
            </div>
            <div>
              <div class="user_undergo user_undergo_a">
                远景照片：
              </div>
              <img
                v-for="(item, i) in info.DistantShotsList"
                :key="i"
                :src="item.Url"
                style="width: 80px;height:80px;display: inline-block;padding: 2px"
                @click="distantShotsPreview"
              />
            </div>
            <div class="user_undergo user_undergo_a">
              整改原因：{{ info.Correct }}
            </div>
            <div class="user_undergo user_undergo_a">
              赋色人员：{{ info.Coloration_Name }}
            </div>
          </div>
        </div>
        <div v-for="(items, j) in infoShopColorations" :key="j">
          <div v-if="items.Status == '1'" class="job_describe_cengter">
            <div class="user_undergo_box">
              <div
                class="user_undergo user_undergo_a"
                style="font-size: 16px; border-bottom: 1px solid #dfdfdf"
              >
                <span
                  style="
                    display: inline-block;
                    border-bottom: 2px solid #3d91fa;
                    height: 28px;
                  "
                >
                  网格员回复
                </span>
              </div>
              <div>
                <div class="user_undergo user_undergo_a">
                  近景照片：
                </div>
                <img
                  v-for="(item, i) in items.CloseShotsFile"
                  :key="i"
                  :src="item.Url"
                  style="width: 80px;height:80px;display: inline-block;padding: 2px"
                  @click="closeShotsPreviewhf(items.CloseShotsFile, i)"
                />
              </div>
              <div>
                <div class="user_undergo user_undergo_a">
                  远景照片：
                </div>
                <img
                  v-for="(item, i) in items.DistantShotsFile"
                  :key="i"
                  :src="item.Url"
                  style="width: 80px;height:80px;display: inline-block;padding: 2px"
                  @click="distantShotsPreviewhf(items.DistantShotsFile, i)"
                />
              </div>
              <div class="user_undergo user_undergo_a">
                备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：{{
                  items.Remarks
                }}
              </div>
              <div class="user_undergo user_undergo_a">
                回复人员：{{ items.Staff_Name }}
              </div>
            </div>
          </div>
          <div v-if="items.Status == '0'" class="job_describe_cengter">
            <div class="user_undergo_box">
              <div
                class="user_undergo user_undergo_a"
                style="font-size: 16px; border-bottom: 1px solid #dfdfdf"
              >
                <span
                  style="
                    display: inline-block;
                    border-bottom: 2px solid #3d91fa;
                    height: 28px;
                  "
                >
                  执法队员赋色
                </span>
              </div>
              <div class="user_undergo user_undergo_a">
                整改原因：{{ items.Remarks }}
              </div>
              <div class="user_undergo user_undergo_a">
                回复人员：{{ items.Staff_Name }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <van-form>
            <van-field readonly label="近景照片：" />
            <div style="padding: 10px;background: white">
              <upload-file :limit="2" @fetch-data="fetchCloseShots" />
              <label style="font-size: 15px;color: red;">
                拍摄照片请尽可能露出门牌
              </label>
            </div>
            <van-field readonly label="远景照片：" />
            <div style="padding: 10px;background: white">
              <upload-file :limit="2" @fetch-data="fetchDistantShots" />
              <label style="font-size: 15px;color: red;">
                拍摄照片请尽可能露出门牌
              </label>
            </div>
            <van-field
              id="zgyy"
              v-model="formData.remarks"
              rows="4"
              autosize
              label="备 注："
              type="textarea"
              placeholder="请填写备注"
            />
            <div style="margin: 16px;">
              <van-row>
                <van-col :span="24" style="text-align: right;padding: 5px">
                  <van-button round block type="info" @click="Save()">
                    回复
                  </van-button>
                </van-col>
              </van-row>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import store from '@/store'
  import Base64 from '@/util/Base64'
  import { Emas } from '@/assets/js/Emas'
  import { Dialog, ImagePreview, Toast } from 'vant'
  import {
    istAdministrationCell,
    istAdministrationCellFallback,
    shopColorationCell,
  } from '@/api/ist/ist/ist'
  import { systemUrl } from '@/config'
  import { getShopColorationInfo } from '@/api/workBench/shop/shop'
  import uploadFile from '@/components/UploadFile'

  export default {
    name: 'IstShopColorationCell',
    components: { uploadFile },
    data() {
      return {
        pkValue: '',
        info: {}, //详情数据
        fileList: [],
        formData: {
          pkValue: '',
          istID: '',
          seqID: '',
          nodeID: '',
          UserID: 0,
          closeShots: '', //近景照片
          distantShots: '', //远景照片
          remarks: '',
        },
        infoShopColorations: {}, //详情数据
        hfNr: '',
        filePath: '',
        closeShotsList: [],
        distantShotsList: [],
        closeShotsArr: [], //近景照片
        distantShotsArr: [], //远景照片
      }
    },
    created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      this.pkValue = Base64.decode(this.$route.query.pkValue)
      this.formData.pkValue = this.pkValue
      this.formData.UserID = userInfo.User_ID
      this.formData.istID = Base64.decode(this.$route.query.istID)
      this.formData.seqID = Base64.decode(this.$route.query.seqID)
      this.formData.nodeID = Base64.decode(this.$route.query.nodeID)
      this.fetchData()
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'istAdministrationCell',
        '城管-户外广告网格审批',
        'zzdcg.yy.gov.cn/istShopColorationCell'
      )
    },
    methods: {
      //获取行政审批详情
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopColorationInfo({ PK_Value: this.pkValue })
        console.log(data)
        data.CloseShotsList = this.makeFileUrl(data.CloseShotsList)
        data.DistantShotsList = this.makeFileUrl(data.DistantShotsList)
        this.info = data
        for (let i = 0; i < data.ShopColorations.length; i++) {
          data.ShopColorations[i].CloseShotsFile = this.makeFileUrl(
            data.ShopColorations[i].CloseShotsFile
          )
          data.ShopColorations[i].DistantShotsFile = this.makeFileUrl(
            data.ShopColorations[i].DistantShotsFile
          )
          if (data.ShopColorations[i].Status == '0') {
            data.ShopColorations[i].Remarks = data.ShopColorations[
              i
            ].Remarks.split('|')[1]
          }
        }
        this.infoShopColorations = data.ShopColorations
        //获取审批意见
        Toast.clear()
      },
      async Save() {
        let closeShots = ''
        let distantShots = ''
        if (this.closeShotsArr.length > 0) {
          for (var i = 0; i < this.closeShotsArr.length; i++) {
            closeShots += this.closeShotsArr[i] + '|'
          }
          this.formData.closeShots = closeShots.substring(
            0,
            closeShots.lastIndexOf('|')
          )
        }
        if (this.distantShotsArr.length > 0) {
          for (var i = 0; i < this.distantShotsArr.length; i++) {
            distantShots += this.distantShotsArr[i] + '|'
          }
          this.formData.distantShots = distantShots.substring(
            0,
            distantShots.lastIndexOf('|')
          )
        }
        if (closeShots == '') {
          Dialog({ message: '请上传近景照片！' })
          return
        }
        if (distantShots == '') {
          Dialog({ message: '请上传远景照片！' })
          return
        }
        if (this.formData.remarks == '') {
          Dialog({ message: '请输入回复内容！' })
          return
        }
        //网格员回复
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { success, data, msg } = await shopColorationCell(this.formData)
        if (success) {
          Toast.clear()
          Dialog({ message: msg })
          this.onClickRight()
        } else {
          Toast.clear()
          Dialog({ message: msg })
        }
      },
      previewBefore() {
        ImagePreview({
          images: this.fileList,
        })
      },
      getFileList(val) {
        console.log(val)
        this.fileList = val
      },
      //跳转到流程列表
      onClickRight() {
        this.$router.push({
          path: '/istIndex',
        })
      },
      //路径拼接
      makeFileUrl(fileList) {
        if (fileList.length > 0) {
          for (let i = 0; i < fileList.length; i++) {
            fileList[i].Url = systemUrl + fileList[i].Url
            switch (fileList[i].Type) {
              case 1:
                this.closeShotsList.push(fileList[i].Url)
                break
              case 2:
                this.distantShotsList.push(fileList[i].Url)
                break
              case 3:
                this.outDoorShotsList.push(fileList[i].Url)
                break
              case 7:
                this.certificateShotsList.push(fileList[i].Url)
                break
            }
          }
        }
        return fileList
      },
      //照片预览
      closeShotsPreview() {
        ImagePreview({
          images: this.closeShotsList,
        })
      },
      distantShotsPreview() {
        ImagePreview({
          images: this.distantShotsList,
        })
      },
      closeShotsPreviewhf(File, key) {
        var FileUrl = []
        FileUrl.push(File[key].Url)
        console.log(FileUrl)
        ImagePreview({
          images: FileUrl,
        })
      },
      distantShotsPreviewhf(File, key) {
        var FileUrl = []
        FileUrl.push(File[key].Url)
        console.log(FileUrl)
        ImagePreview({
          images: FileUrl,
        })
      },
      //照片上传回调方法
      fetchCloseShots(pathList) {
        this.closeShotsArr = pathList
      },
      fetchDistantShots(pathList) {
        this.distantShotsArr = pathList
      },
    },
  }
</script>

<style scoped>
  .job_describe_cengter {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }
  .job_describe_cengter2 {
    padding: 0.4rem;
    border-bottom: 0.013333rem solid #eee;
  }

  .job_describe_cengter_header {
    font-size: 0.426667rem;
    font-weight: 700;
    color: #181818;
  }

  .job_describe_top {
    position: relative;
    width: 100%;
    padding: 0.273333rem 0.373333rem;
    font-size: 0.373333rem;
    background-color: #fff;
    border-bottom: 0.013333rem solid #eee;
  }
  .jjxx {
    position: relative;
    padding-left: 0.473333rem;
    font-size: 0.426667rem;
    font-weight: bold;
  }
  .jjxx::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../../assets/images/workBench/detail.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
  .job_describe {
    width: 100%;
    margin-top: -1.333333rem;
    overflow: hidden;
    line-height: 1.8;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  .min_body {
    padding-right: 0.32rem;
    padding-left: 0.32rem;
  }
  .jobshwheader {
    width: 100%;
    height: 2.026667rem;
    font-size: 0.32rem;
    background-color: #2778f8;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .user_undergo2 {
    width: 100%;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 2;
    color: #333;
    text-overflow: ellipsis;
    white-space: pre-wrap;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background-size: auto;
    background-size: 100%;
  }
  .corporate_information {
    margin-top: 0.266667rem;
    overflow: hidden;
    background-color: #fff;
    border-radius: 0.213333rem;
  }
  ::v-deep .van-cell__title {
    width: 5em;
    margin-right: 0;
  }
  ::v-deep ;
</style>
